<template>
  <div class="mb-6">
    <div
      class="container mx-auto relative w-full flex flex-row mt-6 h-11 mb-6 max-w-desktop"
    >
      <BackButton
        type="dark"
        @click="$router.back()"
        class="absolute left-4 top-0"
      />
      <div class="flex flex-col items-center w-full justify-center">
        <h3 class="capitalize">
          {{ $t("message.ranking") }}
        </h3>
        <p>{{ $t("message.making-more-impact") }}</p>
      </div>
    </div>
    <div class="flex flex-col px-4 md:px-0 gap-2">
      <TaskRankingLink
        class="w-full"
        v-for="(task, index2) in tasks"
        :key="index2"
        :position="index2 + 1"
        :task="task"
      />
    </div>
    <div
      v-if="appsyncLoading"
      class="flex w-full justify-center items-center mt-10"
    >
      <AppleSpinner />
    </div>
  </div>
</template>
<script>
import AppleSpinner from "@/components/shared/AppleSpinner.vue";
import TaskRankingLink from "@/components/tasks/TaskRankingLink.vue";
import {
  GET_TEAM_ID,
  GET_TEAM_TASKS
} from "@/graphql/queries/user/userQueries.js";
import { KENTICO_GET_TEAM_AND_TASKS } from "@/kentico-api/teams.js";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  name: "TeamTasks",
  components: {
    BackButton,
    TaskRankingLink,
    AppleSpinner
  },
  data() {
    return {
      appsyncLoading: true,
      errorLoadingTeam: false,
      kenticoLoading: false,
      kenticoTeam: null,
      teamId: null,
      kenticoCodename: null,
      nextToken: null,
      tasks: []
    };
  },
  apollo: {
    appsyncTeam: {
      query: GET_TEAM_ID,
      update(data) {
        let team;
        if (data.getTeam) {
          team = data.getTeam;
          this.teamId = team.id;
          this.kenticoCodename = team.kenticoCodename;
        } else {
          this.errorLoadingTeam = true;
          this.appsyncLoading = false;
        }
        return team;
      },
      variables() {
        return {
          slug: this.$route.params.slug
        };
      },
      // `cache-and-network` results in the `update` method being called twice
      // `no-cache` results in the `update` method being called once
      fetchPolicy: "no-cache"
    },
    appsyncMoreTasks: {
      query: GET_TEAM_TASKS,
      update(data) {
        let team;
        if (data.getVisibleTeamTasks) {
          team = data.getVisibleTeamTasks;
          this.getKenticoTeamAndTasks(
            this.kenticoCodename,
            this.getKenticoTaskCodenames(team.tasks)
          );
        } else {
          this.errorLoadingTeam = true;
        }
        this.appsyncLoading = false;
        return team;
      },
      variables() {
        return {
          teamId: this.teamId,
          nextToken: this.nextToken,
          limit: 50
        };
      },
      skip() {
        return !this.teamId;
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    loadMore() {
      this.nextToken = this.appsyncMoreTasks.nextToken;
    },
    pushTasks(tasks) {
      if (!this.appsyncMoreTasks) return null;
      const sortedTask = [];
      // kenticoTeam.tasks.items lists tasks in the order we want to display them
      tasks.forEach(kenticoTask => {
        const appsyncTask = this.appsyncMoreTasks.tasks.find(appsyncTask => {
          return appsyncTask.kenticoCodename === kenticoTask._system_.codename;
        });
        if (appsyncTask) {
          sortedTask.push({
            ...kenticoTask,
            id: appsyncTask.id,
            donationsTotal: appsyncTask.donationsTotal || 0,
            pointsOnCompletion: appsyncTask.pointsOnCompletion,
            progress: appsyncTask.progress,
            taskType: appsyncTask.taskType,
            pointsAwarded: appsyncTask.pointsAwarded,
            taskCompletionsCount: appsyncTask.taskCompletionsCount
          });
        }
      });
      sortedTask.sort((a, b) => {
        return a.donationsTotal <= b.donationsTotal ? 1 : -1;
      });
      this.tasks = [...sortedTask];
    },
    getKenticoTeamAndTasks(teamCodename, taskCodenames) {
      this.$apollo
        .query({
          client: "kenticoClient",
          query: KENTICO_GET_TEAM_AND_TASKS,
          variables: {
            teamCodename: teamCodename,
            taskCodenames: taskCodenames
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          if (response.data.team) {
            this.pushTasks(response.data.team.tasks.items);
          } else {
            this.errorLoadingTeam = true;
          }
          this.kenticoLoading = false;
        });
    },
    getKenticoTaskCodenames(tasks) {
      if (tasks.length == 0) return [""];
      else return tasks.map(task => task.kenticoCodename);
    }
  }
};
</script>
<style lang="postcss"></style>
